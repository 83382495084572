import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Head from '../components/Head';

const NotFoundPage = () => {
    const [t] = useTranslation();

    return (
        <>
            <Head title={t('Page Not Found')} />
            <Typography>{t(':.[')}</Typography>
        </>
    );
};

export default NotFoundPage;
