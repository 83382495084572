import { Container, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import Head from '../components/Head';
import Colors from '../constants/Colors';

const styles = {
    heroContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    heroButtons: {
        marginTop: 32,
    },
    backgroundGray: {
        backgroundColor: Colors.lightGray,
        minHeight: '100vh',
    },
};

const HomeScreen = ({ classes }) => {
    return (
        <div className={classes.backgroundGray}>
            <Head title='HelloWorld' />
            <div className={classes.heroContent}>
                <Container>
                    <Typography variant='h1' align='center' style={{ marginTop: 200 }}>
                        Coming Soon...
                    </Typography>
                </Container>
            </div>
        </div>
    );
};

export default withStyles(styles)(HomeScreen);
