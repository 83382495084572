import React from 'react';
import { Typography, Container, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import Card from './Card';
import Colors from '../constants/Colors';

import roboto from '../assets/images/roboto.gif';
// import mayc from '../assets/images/mayc.png';
const styles = {
    header: {
        display: 'flex',
        flexDirection: 'row',
    },
    titleCard: {
        display: 'flex',
        flex: 1,
        padding: '0 10px',
    },
    picCard: {
        display: 'flex',
        flex: 1,
        padding: '0',
    },
    defaultCard: {
        display: 'flex',
        flex: 1,
        padding: '10px',
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    gray: {
        color: Colors.gray,
        opacity: 0.4,
    },
};

const Header = ({ title, classes }) => {
    return (
        <Container>
            <Grid container spacing={0} className={classes.header}>
                <Grid item md={3}>
                    <Card className={classes.titleCard}>
                        <Typography variant='h3'>No lies, Just Love.</Typography>
                    </Card>
                </Grid>
                <Grid item md={3}>
                    <Card className={classes.picCard}>
                        <img src={roboto} alt='' style={{ backgroundSize: 'contain', width: '100%' }} />
                    </Card>
                </Grid>
                <Grid item md={3}>
                    <Card className={classes.defaultCard}>
                        <Typography variant='h6' className={classes.gray}>
                            About
                        </Typography>
                        <Typography variant='button'>Hello world!</Typography>
                    </Card>
                </Grid>
                <Grid item md={3}>
                    <Card className={classes.defaultCard}>
                        <Typography variant='h6' className={classes.gray}>
                            Navigation
                        </Typography>
                        <Typography variant='button'>Home</Typography>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default withStyles(styles)(Header);
