import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Colors from './constants/Colors';
import * as ROUTES from './constants/Routes';
import HomeScreen from './screens/HomeScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import HelveticaNeue from './assets/fonts/HelveticaNeue/HelveticaNeue.ttf';
import './App.css';

const helveticaNeue = {
    fontFamily: 'HelveticaNeue',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    src: `
    local('HelveticaNeue'),
    local('Helvetica Neue'),
    url(${HelveticaNeue}) format('ttf')
    `,
};

const fontStyles = {
    h1: {
        fontSize: 48,
        lineHeight: '56px',
        fontFamily: 'HelveticaNeue',
        letterSpacing: '-0.4px',
        fontWeight: 700,
    },
    h2: {
        fontSize: 36,
        lineHeight: '40px',
        fontFamily: 'HelveticaNeue',
        letterSpacing: '-0.4px',
        fontWeight: 700,
    },
    h3: {
        fontSize: 32,
        lineHeight: '50px',
        fontFamily: 'HelveticaNeue',
        letterSpacing: '-0.2px',
        fontWeight: 700,
    },
    h4: {
        fontSize: 24,
        lineHeight: '24px',
        fontFamily: 'HelveticaNeue',
        letterSpacing: '0px',
        fontWeight: 700,
    },
    h5: {
        fontSize: 20,
        lineHeight: '16px',
        fontFamily: 'HelveticaNeue',
        letterSpacing: '0px',
        fontWeight: 700,
    },
    h6: {
        fontSize: 14,
        lineHeight: '16px',
        fontFamily: 'HelveticaNeue',
        letterSpacing: '0px',
        fontWeight: 700,
    },
    subtitle1: {
        fontSize: 20,
        lineHeight: '24px',
        fontFamily: 'HelveticaNeue',
        letterSpacing: '0px',
        fontWeight: 700,
    },
    subtitle2: {
        fontSize: 16,
        lineHeight: '16px',
        fontFamily: 'HelveticaNeue',
        letterSpacing: '0px',
        fontWeight: 700,
    },
    body1: {
        fontSize: 14,
        lineHeight: '24px',
        fontFamily: 'HelveticaNeue',
        letterSpacing: '-0.1px',
        fontWeight: 400,
    },
    body2: {
        fontSize: 14,
        lineHeight: '22px',
        fontFamily: 'HelveticaNeue',
        letterSpacing: '0px',
        fontWeight: 300,
    },
    button: {
        fontSize: 20,
        lineHeight: '24px',
        fontFamily: 'HelveticaNeue',
        letterSpacing: '0px',
        fontWeight: 700,
    },
    caption: {
        fontSize: 12,
        lineHeight: '16px',
        fontFamily: 'HelveticaNeue',
        letterSpacing: '0.1px',
        fontWeight: 700,
    },
};

const theme = createTheme({
    palette: {
        primary: {
            main: Colors.richBlackFogra,
        },
    },
    typography: {
        htmlFontSize: 18,
        h1: {
            ...fontStyles.h1,
        },
        h2: {
            ...fontStyles.h2,
        },
        h3: {
            ...fontStyles.h3,
        },
        h4: {
            ...fontStyles.h4,
        },
        h5: {
            ...fontStyles.h5,
        },
        h6: {
            ...fontStyles.h6,
        },
        subtitle1: {
            ...fontStyles.subtitle1,
        },
        subtitle2: {
            ...fontStyles.subtitle2,
        },
        body1: {
            ...fontStyles.body1,
        },
        body2: {
            ...fontStyles.body2,
        },
        button: {
            ...fontStyles.button,
            textTransform: 'none',
        },
        caption: {
            ...fontStyles.caption,
            letterSpacing: '0',
            textTransform: 'none',
        },
        overline: {},
        fontFamily: [
            '"Helvetica Neue"',
            '"HelveticaNeue"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    components: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [helveticaNeue],
            },
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <React.Fragment>
                <Router>
                    <Switch>
                        <Route exact path={ROUTES.HOME} component={HomeScreen} />
                        <Route path={ROUTES.NOTFOUND} component={NotFoundScreen} />
                    </Switch>
                </Router>
            </React.Fragment>
        </ThemeProvider>
    );
}

export default App;
