const Colors = {
    richBlackFogra: '#001219ff', // black
    blueSapphire: '#005f73ff', // blue
    viridianGreen: '#0a9396ff', // light blue
    middleBlueGreen: '#94d2bdff', // light turqoise
    mediumChampagne: '#e9d8a6ff', // white
    gamboge: '#ee9b00ff', // orange
    alloyOrange: '#ca6702ff', // brownish orange
    mahogany: '#bb3e03ff', // dark orange
    rufous: '#ae2012ff', // red
    rubyRed: '#9b2226ff', // dark red
    gray: '#222222',
    lightGray: '#EFEFEF',
};

export default Colors;
