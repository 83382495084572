import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';

const styles = {
    card: {
        margin: 10,
        border: '8px solid black',
    },
};

const Card = ({ classes, className, children }) => {
    return <div className={classNames(className, classes.card)}>{children}</div>;
};

Card.propTypes = {
    onClick: PropTypes.func,
};

export default withStyles(styles)(Card);
